.ScrollTop
  &-Btn
    position: fixed
    bottom: 120px
    right: 20px
    width: 60px
    height: 60px
    border: none
    background: none
    cursor: pointer
    z-index: 10
    svg
      width: 100%
      height: 100%