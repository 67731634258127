@use '../../common/styles/vars' as *
@use 'sass-mq' as * with ($breakpoints: $breakpoints)

.ModalVideo
    &-Modal
        position: relative

    &-Close
        position: absolute
        top: -50px
        right: 10px
        padding: 0
        height: 40px
        width: 40px
        background: transparent
        color: #fff
        border: none
        cursor: pointer
        z-index: 10
        &:after,
        &:before
            content: ''
            display: block
            position: absolute
            width: 4px
            height: 45px
            background: currentColor
            border-radius: 2px
            top: 4px
            transform-origin: 50% 0

        &:after
            transform: rotate(45deg)
            right: 2px

        &:before
            transform: rotate(-45deg)
            left: 2px

    &-Player
        width: 100%
