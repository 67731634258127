@use '../../common/styles/vars' as *
@use 'sass-mq' as * with ($breakpoints: $breakpoints)

.AddToHomeScreen
    &-Wrap
        font-family: -apple-system, BlinkMacSystemFont, Nunito, Roboto, "Helvetica Neue", sans-serif
        position: fixed
        left: 50%
        transform: translateX(-50%)
        display: flex
        flex-flow: row nowrap
        align-items: center
        width: 460px
        background: #fff
        color: #333
        box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.2)
        border-radius: 10px
        z-index: 1000
        &_InstallApp
            bottom: 10px
            max-width: calc(100vw - 20px)
            padding: 6px 40px 6px 6px
            height: 62px
            +mq(lg)
                position: fixed
                right: 20px
                left: unset
                bottom: 10px
                transform: unset
        &_iOS
            bottom: 15px
            max-width: calc(100vw - 30px)
            padding: 15px 40px 15px 15px
            height: 75px
    &-Image
        flex-shrink: 0
        object-fit: cover
        border-radius: 5px
        background: #d9d9d9
        &_InstallApp
            width: 50px
            height: 50px
            margin-right: 6px
        &_iOS
            width: 45px
            height: 45px
            margin-right: 15px
    &-Text
        flex-grow: 1
        font-weight: 400
        font-size: 10px
        user-select: none
        +mq(lg)
            font-size: 12px
            line-height: 1.2
        &_InstallApp
            line-height: 1.1
            margin-right: 10px
        &_iOS
            line-height: 1.2
            & svg
                width: 13px
                height: 13px
                margin: 0 2px -2px
    &-Button
        border: none
        outline: none
        text-transform: none
        cursor: pointer
        &_InstallApp
            flex-shrink: 0
            font-weight: 400
            font-size: 12px
            line-height: 1.16
            background: $color-bg
            color: #fff
            height: 30px
            padding: 8px 10px
            margin-left: auto
            border-radius: 5px
    &-CloseButton
        position: absolute
        display: flex
        justify-content: center
        align-items: center
        width: 24px
        height: 24px
        color: #aaa
        background: #fff
        border-radius: 100%
        border: 1px solid #aaa
        outline: none
        text-transform: none
        cursor: pointer
        margin: 0
        padding: 0
        & > svg
            width: 18px
            height: 18px
        &_InstallApp
            top: 50%
            right: 8px
            transform: translateY(-50%)
        &_iOS
            top: 10px
            right: 10px
