@use '../../common/styles/vars' as *
@use 'sass-mq' as * with ($breakpoints: $breakpoints)
@use 'mixins' as *
@import "~normalize.css"

+font-face('Proxima Nova', 'ProximaNova-LightIt', 300, 'italic')
+font-face('Proxima Nova', 'ProximaNovaT-Thin', 100)
+font-face('Proxima Nova', 'ProximaNova-Light', 300)
+font-face('Proxima Nova', 'ProximaNova-Regular', 400)
+font-face('Proxima Nova', 'ProximaNova-Bold', 700)
+font-face('Proxima Nova', 'ProximaNova-Black', 900)

html
    height: 100%
    background: #fff

body
    min-height: 100%
    font-family: 'Proxima Nova', Arial, sans-serif
    color: #fff
    display: flex
    flex-direction: column
    overflow-x: hidden
    min-width: 320px
    background: $color-bg

*,
*:before,
*:after
    box-sizing: border-box

#root
    flex: 1 1
    display: flex
    flex-direction: column

.Main
    flex-grow: 1
    display: flex
    flex-direction: column

.Image
    display: block
    max-width: 100%

.Wrapper
    max-width: 960px
    min-width: 320px
    margin-right: auto
    margin-left: auto
    padding-left: 20px
    padding-right: 20px

    +mq(sm)
        padding-left: 40px
        padding-right: 40px

.Title
    color: #fff
    font-size: 30px
    margin: 0 0 35px
    text-transform: uppercase
    +mq(md)
        text-align: center

.Text
    &_theme
        &_dark
            a
                &,
                &:link,
                &:visited,
                &:hover,
                &:active
                    color: #fff
                    text-decoration: underline

.swiper-container-horizontal
    > .swiper-pagination-bullets
        .swiper-pagination-bullet
            margin: 0 5px

.swiper-pagination-bullet
    opacity: 1
    height: 10px
    width: 10px
    border-radius: 0
    &:focus
        outline: none

.swiper-button-next,
.swiper-button-prev
    outline: none

.swiper-pagination-bullet-active
    height: 20px
    width: 20px

.ReactModal__Body--open
    overflow: hidden
    padding-right: 17px

.ReactModal__Overlay
    opacity: 0
    transition: opacity 400ms ease-in-out

.ReactModal__Overlay--after-open
    opacity: 1

.ReactModal__Overlay--before-close
    opacity: 0