@use './vars' as *

@mixin font-face($font-family, $url, $weight, $style: 'normal')
    @font-face
        font-family: '#{$font-family}'
        src: url('../fonts/#{$url}.woff2') format('woff2'), url('../fonts/#{$url}.woff') format('woff')
        font-weight: $weight
        font-style: $style

@function em($pixels, $context: $base-font-size)
    @return #{$pixels/$context}em

@function rem($pixels)
    @return #{$pixels/$base-font-size}rem