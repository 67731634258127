@use '../../common/styles/vars' as *
@use 'sass-mq' as * with ($breakpoints: $breakpoints)

.HeaderMain
  background: $color-bg
  overflow: hidden
  position: relative
  display: flex
  align-items: center
  height: 60px

  +mq(sm)
    height: 124px

  &-Wrapper
    width: 100%
    padding: 0 36px
    display: flex
    justify-content: space-between
    align-items: center

  &-SwitchLang
    position: absolute
    top: 50%
    right: 20px
    transform: translateY(-50%)
    +mq(sm)
      right: 40px
    +mq(md)
      right: 60px

  &-Image
    height: 33px

    +mq(sm)
      height: auto

  &-Address
    font-size: 10px
    color: #e6e6e6
    text-align: center
    width: 230px
    display: flex
    flex-direction: column
    align-items: flex-end

    +mq(sm)
      flex-direction: row
      width: auto
      font-size: 14px

    a
      margin-left: 5px
      color: #e6e6e6

  &-NoWrap
    display: flex
    flex-wrap: wrap
    justify-content: center
    +mq(400px)
      display: inline
